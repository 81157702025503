import * as React from "react"
import IndexWindmill from "../components/animations/IndexWindmill"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function IndexPage(props) {
  const lang = props.pageContext.langKey

  return (
    <div>
      <Layout isHome lang={lang}>
        <Seo title="Startsida" />
        <div className="h-full w-full relative">
          <div className="w-full h-full">
            <div className="absolute h-full w-full">
              <IndexWindmill />
            </div>
            <div className="relative h-full w-full flex flex-col flex-wrap items-center md:justify-center pt-16 lg:pt-0 z-50">
              <div className="container font-semibold intro-text text-xl md:text-2xl ">
                <div className="leading-relaxed">
                  <p>
                    Bodecker Partners expertis inom den nordiska elmarknaden,
                    såväl som elcertifikat och utsläppsrätter, hör till Sveriges
                    främsta. Vi erbjuder oberoende rådgivning och hantering av
                    marknadsprisrisker till investerare i nordisk förnybar
                    elproduktion.
                  </p>
                  <p>
                    Bodecker Partners – äkta oberoende rådgivning och
                    förvaltning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
